import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import Grid from "@material-ui/core/Grid";
import Item from "../item";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography} from "@material-ui/core";
import Img from "gatsby-image";
import CardContent from "@material-ui/core/CardContent";
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
}));

const HosPage = props => {
  const data = useStaticQuery(graphql`
      query {
          allImageSharp {
              edges {
                  node {
                      fluid(maxWidth: 700) {
                          aspectRatio
                          base64
                          originalImg
                          originalName
                          presentationHeight
                          presentationWidth
                          sizes
                          src
                          srcSetWebp
                          srcSet
                          srcWebp
                          tracedSVG
                      }
                  }
              }
          }
      }
  `)
  const { allImageSharp: { edges } } = data;
  const classes = useStyles();

  function getImage(name) {
    let image = "";

    edges.forEach(edge => {
      if (name === edge.node.fluid.originalName) {
        image = edge.node.fluid;
      }
    });

    return image
  }

  return (
        <div className={classes.root}>
          <Layout>
          <CssBaseline />
          <SEO title="Retail products" />
          <div className={classes.toolbar} />
          <Typography variant="h2" component="h1">
            Hospitality Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Hotels, resorts and conference centers share common needs for literature and signage display fixtures. Clear Industries provides a wide range of display solutions to meet these specific needs. With our many years of experience, we are able to offer both common and highly custom display solutions--all at factory-direct pricing. Other items commonly used in the hospitality industry includes podiums and lecterns for use in conference rooms and greeting stations. Clear Industries offers an assortment of attractive podiums and lecterns at surprisingly affordable prices. Click here to visit our podium photo gallery. Our product menu provides an assortment display styles and types for your review.
            <br/>
            <br/>
            <Typography variant="h5" component="p">Contact</Typography>
            Toll-Free 800-383-0458<br/>
            Local 612-521-1172<br/>
            After Hours 612-669-7364<br/>
            You may fax inquiries and orders to us at 612-521-1447.<br/>
          </Typography>
          <br/>
          <Typography variant="h4" component="h1">
          Sign Holders & Table Tents
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Available in a variety of styles, sizes and thicknesses. Clear Industries' sign holders and table tents are a clear display solution for your signage needs.

            You can economically enhance your signage appeal with polished edges and custom imprinting.

            You have a custom size sign? No problem, we can provide a perfect fit with our custom manufacturing capabilities!

            Clear presentation with factory direct pricing is the right solution.

            Call Today - Our customer service team is ready to assist you!


          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("sh-123-holders.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("sh-sign-holders.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-slantBack.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-top-load.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-bottom-load.jpg")} />
            </Grid>

          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Literature Holders & Brochure Holders
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Clear Industries provides a host of literature and brochure holder solutions for countertop, slat wall, grid wall and drill wall mount applications.

            Whether your literature is common or custom in size, we can produce brochure holders to perfectly fit your needs.

            Common sized brochure holders accommodate 3 3/4" x 8 1/2" double fold brochures, single fold 5 1/2" x 8 1/2" and standard 8 1/2" x 11" literature.

            Custom imprinting is also available

            Clear presentation combined with factory direct pricing equates to great value for our customers.

            Call for a price quote today!
          </Typography>
          <br/>

          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("lh-free-b.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("lh-multi.jpg")} />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Img fluid={getImage("lh-rotate.jpg")} />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Img fluid={getImage("lh-free.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Combination Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            The clear solution for displaying printed ad along with brochures or business cards.

            Our combination displays feature a sign holder with an attached brochure or business card pocket.

            Style, shape and size - A pocket on the left or on the right, or perhaps along side your literature all together? Sitting on a countertop or mounted on a wall? We can provide an exact fit for your display needs.

            Greater Appeal - Consider custom imprinting to enhance your display's effectiveness.

            Call Today! Our customer service staff would be glad to assist you with design and pricing.

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("c-custom.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("c-custom-2.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Product Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Clear, customized displays can give your product the competitive edge. Clear Industries provides a host of product display solutions for countertop or wall mount applications.

            Cost, visual appeal, durability, all these are issues to consider when designing a product or literature display. We would be glad to assist you in this process. With nearly 20 years of design experience, we can help you maximize your display appeal.

            Make it yours with custom imprinting.

            The Clear Choice - Custom displays at factory direct pricing, that's a perfect fit!

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-calling.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-multi.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-risers.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Counter Top Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Whether for retail products or literature, Clear Industries provides a host of countertop display solutions.

            Have a design in mind that you need help developing? We would be glad to assist you in this process. With nearly 20 years of design and production experience, Clear Industries can help you with a custom fit display solution.

            You Name It! - Increase your name recognition with custom imprinting.

            Clearly Seen - Transparent countertop displays maximize your product and literature exposure.

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-circle.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-display.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-steppers.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Business Card Holders
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            What every desk and lobby should have.

            Choose from the styles shown or let us design a custom holder for you.
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("bc-holders.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Card Holders & Note Pad Trays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Getting Organized - A great way to organize desk tops and counters! Make a home for paper, envelopes, pens, erasers and you name it. Our clear acrylic trays are an attractive alternative to desk and countertop clutter.

            Great on the Wall Too! Add slatwall or gridwall clips and you have a great looking wall mount tray to display gift ware, clothing accessories, collectibles and a host of other things
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("ch-holders.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Ballot & Comment Boxes
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Need feed back? Our acrylic comment and ballot boxes may be just what your looking for.

            Our acrylic countertop fund raisers can offer great appeal for you.

            Custom? Not a problem. Our boxes can come in various shapes, colors and sizes. Other customizable options include built in sign holders, personalized imprinting and locking mechanisms.
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cb-box.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Custom Displays & Concepts
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Clear Industries has a long history of providing custom display and advertising specialty solutions.

            Our in-house laser cutting technology allows us to take unique and detailed display concepts and turn them into reality.

            Other special finishing processes allow us to beautifully polish edges, giving your displays, awards and other acrylic items greater glisten and appeal.

            Call us today to place an order or with any questions you may have. Our customer service team is ready to assist you!
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-prisims.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-ad.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-multi.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-combo.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Display Cases
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Display jewelry, candies, collectibles, giftware, tobacco products and much more. With Clear Industries' custom display cases can offer your product maximum exposure with an elegant appeal.

            Style, shape and size - A pocket on the left or on the right, or perhaps along side your literature all together? Sitting on a countertop or mounted on a wall? We can provide an exact fit for your display needs.

            Options, Options, Options - Hinged doors, sliding doors, locking mechanisms, removable shelves, rotating product, rotating bases, wall-mountable options--our custom possibilities are nearly endless.

            Design Assistance - Have a design in mind that you need help developing? We would be glad to assist you in this process. With nearly 20 years of design and production experience, Clear Industries can help you with a custom fit display solution.

            Colors - Other great display options include black, smoked, mirrored or colored acrylic. A touch of color may give your product the distinction your looking for.

            You Name It! - Increase your name recognition with custom imprinting.
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-display.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("dc-rotate.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Bulk & Merchandise Bins
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Perfect for displaying coffee beans, candy, baked goods, nut bins and more. Clear Industries acrylic bulk bins are an attractive display solution for your bulk merchandising needs.

            Custom Sizes and Styles - Available in various sizes and design, we can design a bin to perfectly fit your needs.
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("bm-bins.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>
          </Layout>
        </div>
  )
};

export default HosPage;