import React from 'react'
import RetailPage from '../components/pageComponents/RetailPage'
import PosPage from '../components/pageComponents/PosPage'
import AdPage from '../components/pageComponents/AdPage'
import RestPage from '../components/pageComponents/RestPage'
import HosPage from '../components/pageComponents/HosPage'

function productsCombined(props) {
  
  return (
    <div>
      <RetailPage />
      <PosPage />
      <AdPage />
      <RestPage />
      <HosPage />
    </div>
  )
}

export default productsCombined
